import React from "react";

const MenuDivider = () => {
    return(<div className="user-menu-divider">
            <div style={{"borderBottom": "1px solid var(--palette-2-3)"}}/>
            <div/>
            
        </div>)
}

export default MenuDivider